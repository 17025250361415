/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { VigiaGuard } from './vigia.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'index',
        loadChildren: () => import('./pages/index/index.module').then(m => m.IndexPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'create-acount-email',
        loadChildren: () => import('./pages/create-acount-email/create-acount-email.module').then(m => m.CreateAcountEmailPageModule)
    },
    {
        path: 'insert-code',
        loadChildren: () => import('./pages/insert-code/insert-code.module').then(m => m.InsertCodePageModule)
    },
    {
        path: 'add-asegurado',
        loadChildren: () => import('./pages/add-asegurado/add-asegurado.module').then(m => m.AddAseguradoPageModule)
    },
    {
        path: 'questions',
        loadChildren: () => import('./pages/questions/questions.module').then(m => m.QuestionsPageModule)
    },
    {
        path: 'create-password',
        loadChildren: () => import('./pages/create-password/create-password.module').then(m => m.CreatePasswordPageModule)
    },
    {
        path: 'circulation-card',
        loadChildren: () => import('./pages/circulation-card/circulation-card.module').then(m => m.CirculationCardPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'recover-password',
        loadChildren: () => import('./pages/recover-password/recover-password.module').then(m => m.RecoverPasswordPageModule)
    },
    {
        path: 'frequent-questions',
        loadChildren: () => import('./pages/frequent-questions/frequent-questions.module').then(m => m.FrequentQuestionsPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'pay-share',
        loadChildren: () => import('./pages/pay-share/pay-share.module').then(m => m.PaySharePageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'download-polizas',
        loadChildren: () => import('./pages/download-polizas/download-polizas.module').then(m => m.DownloadPolizasPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'asegurados',
        loadChildren: () => import('./pages/asegurados/asegurados.module').then(m => m.AseguradosPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'detalle-poliza',
        loadChildren: () => import('./pages/detalle-poliza/detalle-poliza.module').then(m => m.DetallePolizaPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'tarjeta-circulacion-flota',
        loadChildren: () => import('./pages/tarjeta-circulacion-flota/tarjeta-circulacion-flota.module').then(m => m.TarjetaCirculacionFlotaPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'ajustes',
        loadChildren: () => import('./pages/ajustes/ajustes.module').then(m => m.AjustesPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'notificaciones',
        loadChildren: () => import('./pages/notificaciones/notificaciones.module').then(m => m.NotificacionesPageModule),
        canActivate: [VigiaGuard]
    },
    {
        path: 'promociones',
        loadChildren: () => import('./pages/promociones/promociones.module').then(m => m.PromocionesPageModule)
    },
    {
        path: 'cupon-detalle',
        loadChildren: () => import('./pages/cupon-detalle/cupon-detalle.module').then(m => m.CuponDetallePageModule)
    },
    {
        path: 'inicio-denuncia',
        loadChildren: () => import('./pages/siniestro/inicio-denuncia/inicio-denuncia.module').then(m => m.InicioDenunciaPageModule)
    },
    {
        path: 'index-siniestro',
        loadChildren: () => import('./pages/siniestro/index-siniestro/index-siniestro.module').then(m => m.IndexSiniestroPageModule)
    },
    {
        path: 'paso-dos',
        loadChildren: () => import('./pages/siniestro/paso-dos/paso-dos.module').then(m => m.PasoDosPageModule)
    },
    {
        path: 'paso-dos-automotor',
        loadChildren: () => import('./pages/siniestro/automotor/paso-dos-automotor/paso-dos-automotor.module').then(m => m.PasoDosAutomotorPageModule)
    },
    {
        path: 'paso-tres-automotor',
        loadChildren: () => import('./pages/siniestro/automotor/paso-tres-automotor/paso-tres-automotor.module').then(m => m.PasoTresAutomotorPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
