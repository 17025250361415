import { Component, enableProdMode } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from './services/storage.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { RestService } from './services/rest.service';
import { Platform } from '@ionic/angular';
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})

export class AppComponent {
    protected publico = this.storage.getContactoPublico();

    constructor(
        private router: Router,
        private storage: StorageService,
        private rest_service: RestService,
        private platform: Platform
    ) {
        if (environment.production) {
            enableProdMode();
        }
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.activar_push();
        }
    }

    ionViewDidEnter(): void {
        this.verGesto();
    }

    protected async activar_push() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive === 'granted') {
            await PushNotifications.register();

            await PushNotifications.addListener('registration', token => {
                this.rest_service.save_token_push(token.value);
            });

            await PushNotifications.addListener('pushNotificationReceived',
                () => {
                    this.router.navigate(['/notificaciones']);
                });

            await PushNotifications.addListener('pushNotificationActionPerformed',
                () => {
                    this.router.navigate(['/notificaciones']);
                });
        }
    }

    protected close_session() {
        this.router.navigate(['/login']);
        this.storage.delete_all();
        this.publico = this.storage.getContactoPublico();
    }

    protected verGesto(): boolean {
        this.publico = true;
        if (this.storage.get_token() !== null && this.storage.get_token() !== undefined) {
            this.publico = false;
        }
        return this.publico;
    }
}