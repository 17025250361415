export class DetalleImagenSlider {
    private id: number = null;
    private nombre: string = null;
    private descuento: string = null;
    private descripcion: string = null;
    private nombre_categoria: string = null;
    private nombre_empresa: string = null;
    private logo_empresa: string = null;
    private imagen: string = null;
    private id_categoria: number = null;

    public constructor(
        id: number,
        nombre: string,
        descuento: string,
        descripcion: string,
        nombre_categoria: string,
        nombre_empresa: string,
        logo_empresa: string,
        imagen: string,
        id_categoria: number
    ) {
        this.id = id;
        this.nombre = nombre;
        this.descuento = descuento;
        this.descripcion = descripcion;
        this.nombre_categoria = nombre_categoria;
        this.nombre_empresa = nombre_empresa;
        this.logo_empresa = logo_empresa;
        this.imagen = imagen;
        this.id_categoria = id_categoria;
    }

    public get_id(): number {
        return this.id;
    }

    public get_nombre(): string {
        return this.nombre;
    }

    public get_descuento(): string {
        return this.descuento;
    }

    public get_descripcion(): string {
        return this.descripcion;
    }

    public get_nombre_categoria(): string {
        return this.nombre_categoria;
    }

    public get_nombre_empresa(): string {
        return this.nombre_empresa;
    }

    public get_logo_empresa(): string {
        return this.logo_empresa;
    }

    public get_imagen(): string {
        return this.imagen;
    }

    public get_id_categoria(): number {
        return this.id_categoria;
    }
}
