import { Poliza } from './Poliza';
import { RiesgoAuto } from './RiesgoAuto';
import { RiesgoVario } from './RiesgoVario';
import { RiesgoVida } from './RiesgoVida';

export class Asegurado {

    private codigo_asegurado: number = null;
    private documento: string = null;
    private tipo_documento: string = null;
    private polizas: Array<Poliza> = [];
    private nombre: string = null;

    constructor(codigo_asegurado: number, documento: string, tipo_documento: string, nombre: string) {
        this.codigo_asegurado = codigo_asegurado;
        this.documento = documento;
        this.tipo_documento = tipo_documento;
        this.nombre = nombre;
    }

    public agregar_poliza(poliza: Poliza): void {
        this.get_polizas().push(poliza);
    }

    public get_polizas(): Array<Poliza> {
        return this.polizas;
    }

    public setPolizas(polizas: Array<Poliza>): void {
        this.polizas = polizas;
    }

    public get_codigo_asegurado(): number {
        return this.codigo_asegurado;
    }

    public get_documento(): string {
        return this.documento;
    }

    public get_tipo_documento(): string {
        return this.tipo_documento;
    }

    public get_nombre(): string {
        return this.nombre;
    }


    public toJSON(): any {
        return {
            codigo_asegurado: this.codigo_asegurado,
            documento: this.documento,
            tipo_documento: this.tipo_documento,
            nombre: this.nombre,
            polizas: this.polizas.map(poliza => poliza.toJSON())
        };
    }


    public agregarPolizasDesdeJSON(polizas: Array<any>): void {
        polizas.forEach(pol => {
            const poliza = new Poliza(pol.asegurado, pol.descripcion_rama, pol.rama, pol.poliza, pol.inicio_vigencia, pol.fin_vigencia, pol.emision, pol.descripcion_riesgo, pol.saldo_vencido, pol.nro_riesgo, pol.productor, pol.codigo_forma_pago, pol.descripcion_forma_pago, pol.nro_combo, pol.poliza_base, pol.rama_base, pol.combo_secuencia, pol.cantidad_cuotas, pol.descripcion_multiproducto, pol.moneda);
            const riesgosAutos = [];
            const riesgosVida = [];
            pol.riesgos_auto.forEach(riesgoAuto => {
                const rieAuto = new RiesgoAuto(riesgoAuto.anio, riesgoAuto.chasis, riesgoAuto.cobertura_sigla, riesgoAuto.cobertura_descripcion, riesgoAuto.descripcion_riesgo, riesgoAuto.gnc, riesgoAuto.marca, riesgoAuto.modelo, riesgoAuto.motor, riesgoAuto.nro_riesgo, riesgoAuto.patente, riesgoAuto.uso_vehiculo, riesgoAuto.valor_asegurado);
                riesgoAuto.coberturas.forEach(cobert => {
                    rieAuto.agregar_coberturas(cobert);
                });
                riesgosAutos.push(rieAuto);
            });
            pol.riesgos_varios.forEach(riesgoVario => {
                poliza.agregar_coberturas_riesgos_varios(riesgoVario.coberturas);
            });
            pol.riesgos_vida.forEach(riesgoVida => {
                const rieVida = new RiesgoVida(riesgoVida.nro_riesgo, riesgoVida.descripcion_riesgo, riesgoVida.tipo_documento, riesgoVida.descripcion_tipo_documento, riesgoVida.nro_documento);
                riesgosVida.push(rieVida);
            });
            poliza.agregar_riesgos(riesgosAutos);
            poliza.agregar_riesgos_vida(riesgosVida);
            this.agregar_poliza(poliza);
        });


    }



}
