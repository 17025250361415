import { Suplemento } from './../model/Suplemento';
import { Asegurado } from 'src/app/model/Asegurado';
import { Poliza } from './../model/Poliza';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { DetalleImagenSlider } from '../model/DetalleImagenSlider';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    public set_token(token: string): void {
        localStorage.setItem('token', token);
    }

    public get_token(): string | null {
        return localStorage.getItem('token');
    }

    public delete_token(): void {
        localStorage.removeItem('token');
    }

    public set_mail(mail: string): void {
        localStorage.setItem('mail', mail);
    }

    public get_mail(): string {
        return localStorage.getItem('mail');
    }

    public delete_mail(): void {
        localStorage.removeItem('mail');
    }

    public set_dni(dni: string): void {
        localStorage.setItem('dni', dni);
    }

    public get_dni(): string {
        return localStorage.getItem('dni');
    }

    public set_tipo_dni(tipoDni: string): void {
        localStorage.setItem('tipo_dni', tipoDni);
    }

    public get_tipo_dni(): string {
        return localStorage.getItem('tipo_dni');
    }

    public set_code(code: string): void {
        localStorage.setItem('code', code);
    }

    public get_code(): string {
        return localStorage.getItem('code');
    }

    public set_recover(recover: string): void {
        localStorage.setItem('recover', recover);
    }

    public get_recover(): boolean {
        return localStorage.getItem('recover') === 'true';
    }

    public delete_recover(): void {
        localStorage.removeItem('recover');
    }

    public delete_all(): void {
        //conservo las variables que quiero resguardar luego de borrar el localstorage
        const datos_biometricos = this.get_estado_datos_biometricos();
        const primer_ingreso = this.get_primer_ingreso();
        const comentario = this.get_comentario();
        const meGusta = this.get_me_gusta();
        const fecha_feedback = this.get_fecha_feedback();
        //const token = this.get_token();
        //const mail = this.get_mail();
        //const sesion = this.get_cerrar_sesion();
        const permiso_ubicacion = this.get_permiso_geolocalizacion();

        localStorage.clear();
        this.save_primer_ingreso(primer_ingreso);
        this.save_estado_datos_biometricos(datos_biometricos);
        this.save_comentario(comentario, fecha_feedback);
        this.save_me_gusta(meGusta, fecha_feedback);
        //this.set_token(token);
        //this.set_mail(mail);
        //this.save_cerrar_sesion(sesion);
        this.set_permiso_geolocalizacion(permiso_ubicacion);
    }

    public set_updated(updated: Date): void {
        localStorage.setItem('updated', updated.toString());
    }

    public get_updated(): string {
        return new Date(localStorage.getItem('updated')).toLocaleString();
    }

    public tokenVerify(): boolean {
        const decoded = jwt_decode(this.get_token());
        const fechaExp = new Date(decoded['exp'] * 1000);

        return new Date() < fechaExp;
    }

    public set_agrega_elimina_asegurado(asegurado: string) {
        localStorage.setItem('agrega_elimina_asegurado', asegurado);
    }

    public get_agrega_elimina_asegurado() {
        return localStorage.getItem('agrega_elimina_asegurado');
    }

    public delete_agrega_elimina_asegurado(): void {
        localStorage.removeItem('agrega_elimina_asegurado');
    }

    public set_datos_poliza(poliza: Poliza): void {
        localStorage.removeItem('poliza');
        localStorage.setItem('poliza', JSON.stringify(poliza));
    }

    public get_datos_poliza(): string {
        return localStorage.getItem('poliza');
    }

    public save_poliza_tarjeta_circulacion_modificada(poliza: Poliza): void {
        localStorage.removeItem('poliza_modificada');
        localStorage.setItem('poliza_modificada', JSON.stringify(poliza));
    }

    public get_poliza_tarjeta_circulacion_modificada(): string {
        return localStorage.getItem('poliza_modificada');
    }

    public set_datos_asegurado(asegurado: Asegurado): void {
        localStorage.removeItem('asegurado');
        localStorage.setItem('asegurado', JSON.stringify(asegurado));
    }

    public get_datos_asegurado(): string {
        return localStorage.getItem('asegurado');
    }

    public set_polizas(polizas: Array<Poliza>) {
        if (this.getActualizar()) {
            localStorage.removeItem('polizas');
        }
        localStorage.setItem('polizas', JSON.stringify(polizas));
    }

    public get_polizas(): Array<Poliza> {
        const listadoPolizas: Array<Poliza> = [];
        if (JSON.parse(localStorage.getItem('polizas')) !== null) {
            const polizasEnStorage = JSON.parse(localStorage.getItem('polizas')).datos.polizas;

            polizasEnStorage.forEach(polizaJson => {
                const poliza = new Poliza(
                    polizaJson.Asegurado,
                    polizaJson.DscRama,
                    polizaJson.Rama,
                    polizaJson.Poliza,
                    polizaJson.InicioVigencia,
                    polizaJson.FinVigencia,
                    polizaJson.FechaEmision,
                    polizaJson.DscRiesgo,
                    polizaJson.SaldoVencido,
                    polizaJson.NroRiesgo,
                    polizaJson.Productor,
                    polizaJson.FormaPago.codigoFormaPago,
                    polizaJson.FormaPago.detalleFormaPago,
                    polizaJson.ComboNro,
                    polizaJson.ComboPolNroBase,
                    polizaJson.ComboRamPolBase,
                    polizaJson.ComboSecuencia,
                    polizaJson.Cuotas.Cuota.length,
                    polizaJson.DescripcionMultiproducto,
                    polizaJson.DscMoneda
                );

                if (polizaJson.tomador) {
                    poliza.agregar_tomador(polizaJson.tomador);
                }
                //Agrego los riesgos auto
                if (polizaJson.RiesgosAuto) {
                    poliza.agregar_riesgos(polizaJson.RiesgosAuto);
                }
                //Agrego riesgos vida
                if (polizaJson.RiesgosVida && polizaJson.RiesgosVida.length > 0) {
                    poliza.agregar_riesgos_vida(polizaJson.RiesgosVida);
                }
                //Agregar coberturas de riesgos varios
                if (polizaJson.RiesgosVarios && polizaJson.RiesgosVarios.length > 0) {
                    poliza.agregar_coberturas_riesgos_varios(polizaJson.RiesgosVarios);
                }
                //Agregar suplementos
                if (polizaJson.Suplementos) {
                    poliza.agregar_suplementos(polizaJson.Suplementos.Suplemento);
                }
                poliza.agregar_tomador(polizaJson.Tomador);
                listadoPolizas.push(poliza);
            });
            return listadoPolizas;
        }

        return listadoPolizas;

    }

    public save_token_push_date(): void {
        localStorage.removeItem('dateTokenPush');
        localStorage.setItem('dateTokenPush', new Date().toString());
    }

    public get_token_push(): string {
        return localStorage.getItem('dateTokenPush');
    }

    public set_datos_suplementos(suplementos: Array<Suplemento>): void {
        localStorage.removeItem('suplementos');
        localStorage.setItem('suplementos', JSON.stringify(suplementos));
    }

    public get_datos_suplementos(): string {
        return localStorage.getItem('suplementos');
    }

    public set_numero_asegurado(nro_asegurado: number): void {
        localStorage.removeItem('nro_asegurado');
        localStorage.setItem('nro_asegurado', nro_asegurado.toString());
    }

    public get_numero_asegurado(): number {
        return parseInt(localStorage.getItem('nro_asegurado'));
    }

    public set_numero_poliza(nro_poliza: number): void {
        localStorage.removeItem('nro_poliza');
        localStorage.setItem('nro_poliza', nro_poliza.toString());
    }

    public get_numero_poliza(): number {
        return parseInt(localStorage.getItem('nro_poliza'));
    }

    public set_numero_rama(nro_rama: number): void {
        localStorage.removeItem('nro_rama');
        localStorage.setItem('nro_rama', nro_rama.toString());
    }

    public get_numero_rama(): number {
        return parseInt(localStorage.getItem('nro_rama'));
    }

    public set_nombre_asegurado(nombre_asegurado: string): void {
        localStorage.removeItem('nombre_asegurado');
        localStorage.setItem('nombre_asegurado', nombre_asegurado.toString());
    }

    public get_nombre_asegurado(): string {
        return localStorage.getItem('nombre_asegurado');
    }

    public set_codigo_productor(codigo_productor: number): void {
        localStorage.removeItem('codigo_productor');
        localStorage.setItem('codigo_productor', codigo_productor.toString());
    }

    public get_codigo_productor(): number {
        return parseInt(localStorage.getItem('codigo_productor'));
    }

    public set_enlace_pago(enlace_pago: string): void {
        localStorage.removeItem('enlace_pago');
        localStorage.setItem('enlace_pago', enlace_pago);
    }

    public get_enlace_pago(): string {
        return localStorage.getItem('enlace_pago');
    }

    public set_asegurados(asegurados: string): void {
        localStorage.removeItem('asegurados');
        localStorage.setItem('asegurados', asegurados);
    }

    public get_asegurados(): string {
        return localStorage.getItem('asegurados');
    }

    public getArrayAsegurados(): Array<Asegurado> {
        const listaAsegurados = JSON.parse(localStorage.getItem('asegurados')).datos.asegurados;
        const resultado: Asegurado[] = [];
        listaAsegurados.forEach(asegurado => {
            const ase = new Asegurado(asegurado.codigo_asegurado, asegurado.documento, asegurado.tipo_documento, asegurado.nombre);
            ase.setPolizas(this.agregarPolizasAlAsegurado(asegurado.codigo_asegurado));
            resultado.push(ase);
        });
        return resultado;
    }

    private agregarPolizasAlAsegurado(codigoAsegurado: string): Array<Poliza> {
        const resultado: Array<Poliza> = [];
        this.get_polizas().forEach(poliza => {
            if (poliza.get_asegurado().toString() === codigoAsegurado) {
                resultado.push(poliza);
            }
        });
        return resultado;
    }

    public save_estado_datos_biometricos(estado: boolean): void {
        localStorage.setItem('estado_datos_biometricos', estado.toString());
    }

    public get_estado_datos_biometricos(): boolean {
        const estado: boolean = this.convertir_a_boolean(localStorage.getItem('estado_datos_biometricos'));
        this.save_estado_datos_biometricos(estado);
        return estado;
    }

    public get_primer_ingreso(): boolean {
        const estado = Boolean(localStorage.getItem('primer_ingreso'));
        this.save_primer_ingreso(!estado);
        return estado;

    }

    public save_primer_ingreso(estado: boolean): void {
        localStorage.setItem('primer_ingreso', estado.toString());
    }


    public get_cerrar_sesion(): boolean {
        const estado = Boolean(localStorage.getItem('cerrar_sesion'));
        this.save_primer_ingreso(!estado);
        return estado;

    }

    public save_cerrar_sesion(sesion: boolean): void {
        localStorage.setItem('cerrar_sesion', sesion.toString());
    }

    private convertir_a_boolean(dato: string): boolean {
        if (dato === 'true') {
            return true;
        }
        return false;
    }

    public set_detalles_dispositivo(detalles_dispositivo: string): void {
        localStorage.removeItem('detalles_dispositivo');
        localStorage.setItem('detalles_dispositivo', detalles_dispositivo);
    }

    public get_detalles_dispositivo(): string {
        return localStorage.getItem('detalles_dispositivo');
    }


    public get_latitud(): number {
        return parseFloat(localStorage.getItem('latitud'));
    }

    public set_latitud(latitud: number): void {
        localStorage.removeItem('latitud');
        localStorage.setItem('latitud', latitud.toString());
    }

    public get_longitud(): number {
        return parseFloat(localStorage.getItem('longitud'));
    }

    public set_longitud(longitud: number): void {
        localStorage.removeItem('longitud');
        localStorage.setItem('longitud', longitud.toString());
    }

    public get_permiso_geolocalizacion(): boolean {
        return this.convertir_a_boolean(localStorage.getItem('geolocalizacion'));
    }

    public set_permiso_geolocalizacion(geolocalizacion: boolean): void {
        localStorage.removeItem('geolocalizacion');
        localStorage.setItem('geolocalizacion', geolocalizacion.toString());
    }

    public remove_permiso_geolocalizacion(): void {
        localStorage.setItem('geolocalizacion', 'false');
        localStorage.removeItem('latitud');
        localStorage.removeItem('longitud');
    }

    public setSlideActiva(slide: number): void {
        localStorage.removeItem('slide');
        localStorage.setItem('slide', slide.toString());
    }

    public getSlideActiva(): number {
        const slide = localStorage.getItem('slide');
        if (slide === null || slide === undefined) {
            this.setSlideActiva(0);
            return 0;
        }
        return parseInt(localStorage.getItem('slide'));
    }

    public get_forma_pago_poliza(nroPoliza: number): number {
        const polizas = JSON.parse(localStorage.getItem('polizas')).datos.polizas;
        let codigo = 0;
        polizas.forEach(element => {
            if (element.Poliza === nroPoliza) {
                codigo = parseInt(element.FormaPago.codigoFormaPago);
            }
        });
        return codigo;
    }

    public laPolizaEstaEnPesos(nroPoliza: number): boolean {
        const polizas = JSON.parse(localStorage.getItem('polizas')).datos.polizas;
        let moneda = '';
        polizas.forEach(element => {
            if (element.Poliza === nroPoliza) {
                moneda = element.DscMoneda.toLowerCase();
            }
        });
        return moneda === 'pesos';
    }

    public save_cupon_detalle(cupon: any): void {
        const cuponDatos = JSON.stringify(cupon);
        localStorage.setItem('cuponDetalle', cuponDatos);
    }

    public get_cupon_detalle(): DetalleImagenSlider {
        const cupon = JSON.parse(localStorage.getItem('cuponDetalle'));
        const crearCupon = new DetalleImagenSlider(
            cupon.id,
            cupon.nombre,
            cupon.descuento,
            cupon.descripcion,
            cupon.nombre_categoria,
            cupon.nombreEmpresa,
            cupon.logo_empresa,
            cupon.imagen,
            cupon.id_categoria
        );
        return crearCupon;
    }

    public getContactoPublico(): boolean {
        const publico = localStorage.getItem('contactoPublico');
        if (publico === null || publico === undefined) {
            return true;
        }
        return this.convertir_a_boolean(publico);
    }


    public set_ubicacion(ubicacion: string): void {
        localStorage.setItem('ubicacion', ubicacion);
    }

    public get_ubicacion(): string {
        const ubicacion = localStorage.getItem('ubicacion');
        if (ubicacion === null || ubicacion === undefined) {
            return '';
        }
        return localStorage.getItem('ubicacion');
    }

    public getActualizar(): boolean {
        let seActualiza = false;

        if (localStorage.getItem('desdeActualizar') === 'true') {
            seActualiza = true;
        }
        localStorage.removeItem('desdeActualizar');
        return seActualiza;
    }

    public setActualizar(): void {
        localStorage.setItem('desdeActualizar', 'true');
    }

    public get_comentario(): string {
        const comentario = localStorage.getItem('comentario');
        if (comentario === null || comentario === undefined) {
            return '';
        }
        return comentario;
    }
    
    public set_comentario(comentario: string): void {
        const fecha = new Date();
        localStorage.setItem('fecha_feedback', fecha.toString());//guardo la fecha
        localStorage.removeItem('comentario');
        localStorage.setItem('comentario', comentario);
    }
    
    public save_comentario(comentario: string, fecha_feedback : Date): void {
        localStorage.setItem('fecha_feedback', fecha_feedback.toString());
        localStorage.setItem('comentario', comentario.toString());
    }
    
    public get_me_gusta(): boolean {
        const meGusta = localStorage.getItem('me_gusta');
        if (meGusta === null || meGusta === undefined) {
            return false;
        }
        return this.convertir_a_boolean(meGusta);
    }

    public set_me_gusta(meGusta: boolean): void {
        const fecha = new Date();
        localStorage.removeItem('me_gusta');
        localStorage.setItem('fecha_feedback', fecha.toString());//guardo la fecha
        localStorage.setItem('me_gusta', meGusta.toString());
    }
    
    public get_fecha_feedback(): Date {
        const fecha_feedback = localStorage.getItem('fecha_feedback');
        if (fecha_feedback === null || fecha_feedback === undefined) {
            return null;
        }
        return this.convertir_a_fecha(fecha_feedback);
    }
    
    public save_me_gusta(me_gusta: boolean, fecha_feedback: Date): void {
        localStorage.setItem('fecha_feedback', fecha_feedback.toString());
        localStorage.setItem('me_gusta', me_gusta.toString());
    }

    public removeFeedback() {
        localStorage.removeItem('comentario');
        localStorage.removeItem('me_gusta');
        localStorage.removeItem('fecha_feedback');
    }

    private convertir_a_fecha(fechaString: string): Date | null {
        const fecha = new Date(fechaString);
        return isNaN(fecha.getTime()) ? null : fecha;
    }

}
