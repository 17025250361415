/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */

import { Cuota } from './Cuota';

export class Suplemento {

    private descripcion_operacion: string = null;
    private fin_vigencia: string = null;
    private inicio_vigencia: string = null;
    private premio: number = null;
    private prima: number = null;
    private suplemento: number = null;
    private tipo_operacion: string = null;
    private sub_tipo_operacion: string = null;
    private cuotas: Array<Cuota> = [];

    constructor(
        descripcion_operacion: string,
        fin_vigencia: string,
        inicio_vigencia: string,
        premio: number,
        prima: number,
        suplemento: number,
        tipo_operacion: string,
        sub_tipo_operacion: string,
    ) {
        this.descripcion_operacion = descripcion_operacion;
        this.fin_vigencia = fin_vigencia;
        this.inicio_vigencia = inicio_vigencia;
        this.premio = premio;
        this.prima = prima;
        this.suplemento = suplemento;
        this.tipo_operacion = tipo_operacion;
        this.sub_tipo_operacion = sub_tipo_operacion;
    }

    public agregar_cuotas(cuotas: any): void {
        if (cuotas === undefined) {
            return;
        }
        if (Array.isArray(cuotas)) {
            cuotas.forEach(item => {
                const cuota_loop = new Cuota(
                    item['ComboPolNroBase'] || item['combo_pol_nro_base'],
                    item['ComboRamPolBase'] || item['combo_pol_ram_base'],
                    item['Endoso'] || item['endoso'],
                    item['Estado'] || item['estado'],
                    item['Importe'] || item['importe'],
                    item['NroCuota'] || item['nro_cuota'],
                    item['NroCupon'] || item['nro_cupon'],
                    item['Saldo'] || item['saldo'],
                    item['Vencimiento'] || item['vencimiento'],
                    item['FormaPago'] || item['forma_pago'],
                    item['FechaPago'] || item['fecha_pago']
                );
                this.cuotas.push(cuota_loop);
            });
            return;
        }
        const cuota = new Cuota(
            cuotas['ComboPolNroBase'],
            cuotas['ComboRamPolBase'],
            cuotas['Endoso'],
            cuotas['Estado'],
            cuotas['Importe'],
            cuotas['NroCuota'],
            cuotas['NroCupon'],
            cuotas['Saldo'],
            cuotas['Vencimiento'],
            ('FormaPago' in cuotas || 'forma_pago' in cuotas) ? cuotas['FormaPago'] : null,
            ('FechaPago' in cuotas || 'fecha_pago' in cuotas) ? cuotas['FechaPago'] : null,
        );
        this.cuotas.push(cuota);
        return;

    }

    public get_descripcion_operacion(): string {
        if (this.get_suplemento() == 0) {
            return 'PÓLIZA';
        }
        return this.descripcion_operacion;
    }

    public get_fin_vigencia(): string {
        return this.fin_vigencia;
    }

    public get_inicio_vigencia(): string {
        return this.inicio_vigencia;
    }

    public get_premio(): number {
        return this.premio;
    }

    public get_prima(): number {
        return this.prima;
    }

    public get_suplemento(): number {
        return this.suplemento;
    }

    public get_tipo_operacion(): string {
        return this.tipo_operacion;
    }

    public get_sub_tipo_operacion(): string {
        return this.sub_tipo_operacion;
    }

    public get_cuotas(): Array<Cuota> {
        return this.cuotas;
    }

    public tiene_cuotas_impagas(): boolean {
        let alguna_cuota_impaga = false;
        this.cuotas.forEach(cuota => {
            if (!cuota.pagable()) {
                alguna_cuota_impaga = true;
            }
        });
        return alguna_cuota_impaga;
    }

    public toJSON(): any {
        return {
            descripcion_operacion: this.descripcion_operacion,
            fin_vigencia: this.fin_vigencia,
            inicio_vigencia: this.inicio_vigencia,
            premio: this.premio,
            prima: this.prima,
            suplemento: this.suplemento,
            tipo_operacion: this.tipo_operacion,
            sub_tipo_operacion: this.sub_tipo_operacion,
            cuotas: this.cuotas.map(cuota => cuota.toJSON())
        };
    }

    public suplementoFromJSON(data: any): any {
        const suplemento = new Suplemento(data.descripcion_operacion, data.fin_vigencia, data.inicio_vigencia, data.premio, data.prima, data.suplemento, data.tipo_operacion, data.sub_tipo_operacion);
    }
}
