export class Cuota {
    private combo_pol_nro_base: number = null;
    private combo_pol_ram_base: number = null;
    private endoso: number = null;
    private estado: string = null;
    private importe: number = null;
    private nro_cuota: number = null;
    private nro_cupon: number = null;
    private saldo: number = null;
    private vencimiento: string = null;
    private fecha_pago: string = null;
    private forma_pago: string = null;


    constructor(
        combo_pol_nro_base: number,
        combo_pol_ram_base: number,
        endoso: number,
        estado: string,
        importe: number,
        nro_cuota: number,
        nro_cupon: number,
        saldo: number,
        vencimiento: string,
        forma_pago: string,
        fecha_pago: string
    ) {
        this.combo_pol_nro_base = combo_pol_nro_base;
        this.combo_pol_ram_base = combo_pol_ram_base;
        this.endoso = endoso;
        this.estado = estado;
        this.importe = importe;
        this.nro_cuota = nro_cuota;
        this.nro_cupon = nro_cupon;
        this.saldo = saldo;
        this.vencimiento = vencimiento;
        this.fecha_pago = fecha_pago;
        this.forma_pago = forma_pago;
    }

    public get_combo_pol_nro_base(): number {
        return this.combo_pol_nro_base;
    }

    public get_combo_pol_ram_base(): number {
        return this.combo_pol_ram_base;
    }

    public get_endoso(): number {
        return this.endoso;
    }

    public get_estado(): string {
        return this.estado;
    }

    public get_importe(): number {
        return this.importe;
    }

    public get_nro_cuota(): number {
        return this.nro_cuota;
    }

    public get_nro_cupon(): number {
        return this.nro_cupon;
    }

    public get_saldo(): number {
        return this.saldo;
    }

    public get_forma_pago(): string {
        return this.forma_pago;
    }

    public get_fecha_pago(): string {
        return this.fecha_pago;
    }

    public get_vencimiento(): string {
        return this.vencimiento;
    }

    public pagable(): boolean {
        if (this.saldo > 0) {
            return false;
        }
        return true;
    }

    public mostrar_cupon(): boolean {
        if (this.importe > 0) {
            return true;
        }
        return false;
    }

    public vencida(): boolean {
        if (Date.now() > Date.parse(this.vencimiento)) {
            return true;
        }
        return false;
    }

    public set_estado(estado: string) {
        this.estado = estado;
    }

    public set_saldo(saldo: number) {
        this.saldo = saldo;
    }

    public set_fecha_pago(fecha: string) {
        this.fecha_pago = fecha;
    }

    public set_forma_pago(formaPago: string) {
        this.forma_pago = formaPago;
    }

    public toJSON(): any {
        return {
            combo_pol_nro_base: this.combo_pol_nro_base,
            combo_pol_ram_base: this.combo_pol_ram_base,
            endoso: this.endoso,
            estado: this.estado,
            importe: this.importe,
            nro_cuota: this.nro_cuota,
            nro_cupon: this.nro_cupon,
            saldo: this.saldo,
            vencimiento: this.vencimiento,
            fecha_pago: this.fecha_pago,
            forma_pago: this.forma_pago
        };
    }

    public cuotaFromJSON(data: any): any {
        const cuota = new Cuota(data.combo_pol_nro_base, data.combo_pol_ram_base, data.endoso, data.estado, data.importe, data.nro_cuota, data.nro_cupon, data.saldo, data.vencimiento, data.forma_pago, data.fecha_pago);
    }


}
