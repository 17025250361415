/* eslint-disable @typescript-eslint/naming-convention */
export class RiesgoVida {
    private nro_riesgo: number = null;
    private descripcion_riesgo: string = null;
    private tipo_documento: number = null;
    private descripcion_tipo_documento: string = null;
    private nro_documento: number = null;

    public constructor(
        nro_riesgo: number,
        descripcion_riesgo: string,
        tipo_documento: number,
        descripcion_tipo_documento: string,
        nro_documento: number
    ) {
        this.nro_riesgo = nro_riesgo;
        this.descripcion_riesgo = descripcion_riesgo;
        this.tipo_documento = tipo_documento;
        this.descripcion_tipo_documento = descripcion_tipo_documento;
        this.nro_documento = nro_documento;
    }

    public get_nro_riesgo(): number {
        return this.nro_riesgo;
    }
    public get_descripcion_riesgo(): string {
        return this.descripcion_riesgo;
    }
    public get_tipo_documento(): number {
        return this.tipo_documento;
    }
    public get_descripcion_tipo_docuemnto(): string {
        return this.descripcion_tipo_documento;
    }
    public get_nro_documento(): number {
        return this.nro_documento;
    }


    public toJSON(): any {
        return {
            nro_riesgo: this.nro_riesgo,
            descripcion_riesgo: this.descripcion_riesgo,
            tipo_documento: this.tipo_documento,
            descripcion_tipo_documento: this.descripcion_tipo_documento,
            nro_documento: this.nro_documento
        };
    }

    public riesgoVidaFromJSON(data: any): any {
        const riesgoVida = new RiesgoVida(data.nro_riesgo, data.descripcion_riesgo, data.tipo_documento, data.descripcion_tipo_documento, data.nro_documento);
    }

}
