export class Cobertura {

    private codigo: number = null;
    private nombre: string = null;
    private suma: number = null;

    public constructor(
        codigo: number,
        nombre: string,
        suma: number,
    ) {
        this.codigo = codigo;
        this.nombre = nombre;
        this.suma = suma;
    }

    public get_codigo(): number {
        return this.codigo;
    }

    public get_nombre(): string {
        return this.nombre;
    }

    public get_suma(): number {
        return this.suma;
    }

    public toJSON(): any {
        return {
            codigo: this.codigo,
            nombre: this.nombre,
            suma: this.suma
        };

    }

    public coberturaFromJSON(data: any): any {
        const cobertura = new Cobertura(data.codigo, data.nombre, data.suma);
    }
}
