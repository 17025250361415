export const environment = {
    production: true,
    //baseUrl: 'http://localhost/app_asegurados_back/public/api',
    baseUrl: 'https://aplicaciones.lps.com.ar/app_asegurados_back/public/api',
    //baseUrl: 'https://aplicaciones.lps.com.ar/test/app_asegurados_back/public/api',
    //baseUrl: 'https://aplicaciones.lps.com.ar/test/app_asegurados_back/public/api',
    version: '3.1.17',
    baseSOAP: 'https://com-prod.lps.com.ar/LPS_COM_PROD/servlet/ar.com.glmsa.seguros.comercial.glmmobile.',
    ubicacionSiniestro: '../assets/json/denunciaSiniestro/ubicacionSiniestro.json'
};
