import { RiesgoVida } from './RiesgoVida';
import { RiesgoAuto } from './RiesgoAuto';
import { Suplemento } from './Suplemento';
import { Tomador } from './Tomador';
import { RiesgoVario } from './RiesgoVario';

export class Poliza {

    private asegurado: number = null;
    private descripcion_rama: string = null;
    private poliza: number = null;
    private fin_vigencia: string = null;
    private inicio_vigencia: string = null;
    private emision: string = null;
    private descripcion_riesgo: string = null;
    private tomador: Tomador = null;
    private saldo_vencido: number = null;
    private riesgos_auto: Array<RiesgoAuto> = [];
    private riesgos_vida: Array<RiesgoVida> = [];
    private riesgos_varios: Array<RiesgoVario> = [];
    private nro_riesgo: number = null;
    private rama: number = null;
    private suplementos: Array<Suplemento> = [];
    private productor: number = null;
    private codigo_forma_pago: number;
    private descripcion_forma_pago: string;
    private nro_combo: number;
    private poliza_base: number;
    private rama_base: number;
    private combo_secuencia: number;
    private cantidad_cuotas: number;
    private descripcion_multiproducto: string;
    private moneda: string;

    public constructor(
        asegurado: number,
        descripcion_rama: string,
        rama: number,
        poliza: number,
        inicio_vigencia: string,
        fin_vigencia: string,
        emision: string,
        descripcion_riesgo: string,
        saldo_vencido: number,
        nro_riesgo: number,
        productor: number,
        codigo_forma_pago: number,
        descripcion_forma_pago: string,
        nro_combo: number,
        poliza_base: number,
        rama_base: number,
        combo_secuencia: number,
        cantidad_cuotas: number,
        descripcion_multiproducto: string,
        moneda: string
    ) {
        this.asegurado = asegurado;
        this.descripcion_rama = descripcion_rama;
        this.rama = rama;
        this.poliza = poliza;
        this.fin_vigencia = fin_vigencia;
        this.inicio_vigencia = inicio_vigencia;
        this.emision = emision;
        this.descripcion_riesgo = descripcion_riesgo;
        this.saldo_vencido = saldo_vencido;
        this.nro_riesgo = nro_riesgo;
        this.productor = productor;
        this.codigo_forma_pago = codigo_forma_pago;
        this.descripcion_forma_pago = descripcion_forma_pago;
        this.nro_combo = nro_combo;
        this.poliza_base = poliza_base;
        this.rama_base = rama_base;
        this.combo_secuencia = combo_secuencia;
        this.cantidad_cuotas = cantidad_cuotas;
        this.descripcion_multiproducto = descripcion_multiproducto;
        this.moneda = moneda;
    }

    public agregar_coberturas_riesgos_varios(coberturas: any): void {
        const riesgo_vario = new RiesgoVario(this.descripcion_riesgo);
        riesgo_vario.agregar_coberturas(coberturas);
        this.riesgos_varios.push(riesgo_vario);
        return;
    }

    public agregar_suplementos(suplementos: any): void {
        this.suplementos = [];
        if (Array.isArray(suplementos)) {
            suplementos.forEach(item => {
                if (item['Cuotas'] === undefined) {
                    return;
                }
                const suplemento_loop = new Suplemento(
                    item['DscOperacion'] || item['descripcion_operacion'],
                    item['FinVigencia'] || item['fin_vigencia'],
                    item['InicioVigencia'] || item['inicio_vigencia'],
                    item['Premio'] || item['premio'],
                    item['Prima'] || item['prima'],
                    item['Suplemento'] || item['suplemento'],
                    item['TipoOperacion'] || item['tipo_operacion'],
                    item['SubTipoOperacion'] || item['sub_tipo_operacion']
                );

                if ((item['cuotas'] !== undefined)) {
                    suplemento_loop.agregar_cuotas(item['cuotas']);
                } else {

                    if (item['Cuotas']['Cuota'] !== undefined) {
                        if (Array.isArray(item['Cuotas']['Cuota'])) {
                            suplemento_loop.agregar_cuotas(item['Cuotas']['Cuota']);
                        } else {
                            suplemento_loop.agregar_cuotas([item['Cuotas']['Cuota']]);
                        }
                    }
                }

                this.suplementos.push(suplemento_loop);
            });
            this.suplementos.reverse();
            return;
        }

        const suplemento = new Suplemento(
            suplementos['DscOperacion'] || suplementos['descripcion_operacion'],
            suplementos['FinVigencia'] || suplementos['fin_vigencia'],
            suplementos['InicioVigencia'] || suplementos['inicio_vigencia'],
            suplementos['Premio'] || suplementos['premio'],
            suplementos['Prima'] || suplementos['prima'],
            suplementos['Suplemento'] || suplementos['suplemento'],
            suplementos['TipoOperacion'] || suplementos['tipo_operacion'],
            suplementos['SubTipoOperacion'] || suplementos['sub_tipo_operacion']
        );

        if (('cuotas' in suplementos)) {
            suplementos['Cuotas'] = [];
            suplementos['Cuotas']['Cuotas'] = suplementos['cuotas'];
        }

        suplemento.agregar_cuotas(suplementos?.['Cuotas']?.['Cuota'] || suplementos?.['Suplemento']?.['Cuotas']?.['cuota']);
        this.suplementos.push(suplemento);
        return;
    }

    public agregar_riesgos(riesgos: any): void {
        if (Array.isArray(riesgos)) {
            riesgos.forEach(item => {
                const riesgo_loop = new RiesgoAuto(
                    item['Anio'] || item['anio'],
                    item['Chasis'] || item['chasis'],
                    item['CoberturaAuto'] || item['cobertura_sigla'],
                    item['DscCoberturaAuto'] || item['cobertura_descripcion'],
                    item['DscRiesgo'] || item['descripcion_riesgo'],
                    item['GNC'] || item['gnc'],
                    item['Marca'] || item['marca'],
                    item['Modelo'] || item['modelo'],
                    item['Motor'] || item['motor'],
                    item['NroRiesgo'] || item['nro_riesgo'],
                    item['Patente'] || item['patente'],
                    item['UsoVehiculo'] || item['uso_vehiculo'],
                    item['ValorAsegurado'] || item['valor_asegurado']
                );
                if (('coberturas' in item)) {
                    item['Coberturas'] = [];
                    item['Coberturas']['Cobertura'] = item['coberturas'];
                }

                riesgo_loop.agregar_coberturas(item['Coberturas']['Cobertura']);
                riesgo_loop.agregar_adicional(item['ServicioAdicional']);
                this.riesgos_auto.push(riesgo_loop);
            });
            return;
        }
        const riesgo = new RiesgoAuto(
            riesgos['Anio'] || riesgos?.['riesgo']?.['Anio'],
            riesgos['Chasis'] || riesgos?.['riesgo']?.['Chasis'],
            riesgos['CoberturaAuto'] || riesgos?.['riesgo']?.['CoberturaAuto'],
            riesgos['DscCoberturaAuto'] || riesgos?.['riesgo']?.['DscCoberturaAuto'],
            riesgos['DscRiesgo'] || riesgos?.['riesgo']?.['DscRiesgo'],
            riesgos['GNC'] || riesgos?.['riesgo']?.['GNC'],
            riesgos['Marca'] || riesgos?.['riesgo']?.['Marca'],
            riesgos['Modelo'] || riesgos?.['riesgo']?.['Modelo'],
            riesgos['Motor'] || riesgos?.['riesgo']?.['Motor'],
            riesgos['NroRiesgo'] || riesgos?.['riesgo']?.['NroRiesgo'],
            riesgos['Patente'] || riesgos?.['riesgo']?.['Patente'],
            riesgos['UsoVehiculo'] || riesgos?.['riesgo']?.['UsoVehiculo'],
            riesgos['ValorAsegurado'] || riesgos?.['riesgo']?.['ValorAsegurado']
        );

        riesgo.agregar_coberturas(riesgos?.['Coberturas']?.['Cobertura'] || riesgos?.['riesgo']?.['Coberturas']?.['Cobertura']);
        riesgo.agregar_adicional(riesgos['ServicioAdicional']);
        this.riesgos_auto.push(riesgo);
        return;
    }

    public agregar_riesgos_vida(riesgos: any): void {
        if (Array.isArray(riesgos)) {
            riesgos.forEach(item => {
                const riesgo_vida_loop = new RiesgoVida(
                    item['NroRiesgo'] || item['nro_riesgo'],
                    item['DscRiesgo'] || item['descripcion_riesgo'],
                    item['TipoDocumento'] || item['tipo_documento'],
                    item['DscTipoDocumento'] || item['descripcion_tipo_documento'],
                    item['NroDocumento'] || item['nro_documento']
                );
                this.riesgos_vida.push(riesgo_vida_loop);
            });
            return;
        }
        const riesgo_vida = new RiesgoVida(
            riesgos['NroRiesgo'] || riesgos['nro_riesgo'],
            riesgos['DscRiesgo'] || riesgos['descripcion_riesgo'],
            riesgos['TipoDocumento'] || riesgos['tipo_documento'],
            riesgos['DscTipoDocumento'] || riesgos['descripcion_tipo_documento'],
            riesgos['NroDocumento'] || riesgos['nro_documento']
        );

        this.riesgos_vida.push(riesgo_vida);
        return;
    }

    public agregar_tomador(tomador: any): void {
        this.tomador = new Tomador(
            tomador['CodTomador'] || tomador['codigo_tomador'],
            tomador['NombreTomador'] || tomador['nombre_tomador'],
            tomador['Domicilio'] || tomador['domicilio'],
            tomador['Localidad'] || tomador['localidad'],
            tomador['CodigoPostal'] || tomador['codigo_postal']
        );
    }

    public get_rama(): number {
        return this.rama;
    }

    public set_tomador(tomador: Tomador): void {
        this.tomador = tomador;
    }

    public get_tomador(): Tomador {
        return this.tomador;
    }

    public get_asegurado(): number {
        return this.asegurado;
    }

    public get_descripcion_rama(): string {
        return this.descripcion_rama;
    }

    public get_poliza(): number {
        return this.poliza;
    }

    public get_fin_vigencia(): string {
        return this.fin_vigencia;
    }

    public get_inicio_vigencia(): string {
        return this.inicio_vigencia;
    }

    public get_emision(): string {
        return this.emision;
    }

    public get_descripcion_riesgo(): string {
        return this.descripcion_riesgo;
    }

    public get_saldo_vencido(): number {
        return this.saldo_vencido;
    }

    public get_nro_riesgo(): number {
        return this.nro_riesgo;
    }

    public get_descripcion_riesgo_mostrar(): string {

        if (this.nro_combo > 0) {
            return 'MULTIPRODUCTO';
        }

        if (this.get_riesgos_auto().length > 1 && (this.rama === 4 || this.rama === 18)) {
            return 'FLOTA';
        }

        if (this.get_riesgos_vida().length > 1) {
            return 'RIESGOS VARIOS';
        }

        return this.descripcion_riesgo;
    }

    public get_riesgos_auto(): Array<RiesgoAuto> {
        return this.riesgos_auto;
    }

    public set_riesgos_auto(riesgos: Array<RiesgoAuto>) {
        this.riesgos_auto = riesgos;
    }

    public get_riesgos_vida(): Array<RiesgoVida> {
        return this.riesgos_vida;
    }

    public get_suplementos(): Array<Suplemento> {
        return this.suplementos;
    }

    public corresponde_tarjeta_circularcion(): boolean {
        if ((this.rama === 4 || this.rama === 18) && this.riesgos_auto.length >= 1) {
            return true;
        }
        return false;
    }

    public corresponder_asistencia_mecanica(): boolean {
        if (this.rama === 4 || this.rama === 18) {
            if (this.riesgos_auto.length > 1) {
                return true;
            }
            if (this.riesgos_auto[0].get_adicional() !== ' ') {
                return true;
            }
            return false;
        }
        return false;
    }

    public corresponder_asistencia_hogar(): boolean {
        if (this.rama === 22) {
            return true;
        }
        if (this.rama === 25) {
            return true;
        }
        if (this.rama === 29) {
            return true;
        }
        return false;
    }

    public get_productor(): number {
        return this.productor;
    }

    public tiene_riesgos(): boolean {
        if (this.riesgos_auto.length > 0 || this.riesgos_vida.length > 0 || this.riesgos_varios.length > 0) {
            return true;
        }
        return false;
    }

    public get_riesgos_varios(): Array<RiesgoVario> {
        return this.riesgos_varios;
    }

    public get_codigo_forma_pago(): number {
        return Number(this.codigo_forma_pago);
    }

    public get_descripcion_forma_pago(): string {
        return this.descripcion_forma_pago;
    }

    public get_nro_combo(): number {
        return Number(this.nro_combo);
    }

    public get_poliza_base(): number {
        return this.poliza_base;
    }

    public get_rama_base(): number {
        return this.rama_base;
    }

    public get_combo_secuencia(): number {
        return this.combo_secuencia;
    }

    public get_cantidad_cuotas(): number {
        return this.cantidad_cuotas;
    }

    public es_hija_multiproducto(): boolean {
        if (this.nro_combo > 0) {
            if (this.poliza !== Number(this.poliza_base)) {
                return true;
            }
        }
        return false;
    }

    public get_icono_rama(): string {
        switch (this.rama) {
            case 1:
                return 'incendio';
            case 2:
                return 'transporte';
            case 4:
                return 'automotores';
            case 5:
                return 'cristales';
            case 6:
                return 'robo';
            case 7:
                return 'rc-caucion';
            case 9:
                return 'rc-caucion';
            case 12:
                return 'accidentes-personales';
            case 13:
                return 'mascotas';
            case 15:
                return 'seguro-tecnico';
            case 17:
                return 'vida-obligatorio';
            case 18:
                return 'motovehiculos';
            case 19:
                return 'transporte';
            case 22:
                return 'combinado-familiar';
            case 23:
                return 'vida-colectivo';
            case 24:
                return 'vida';
            case 25:
                return 'integral-de-comercio';
            case 29:
                return 'integral-de-consorcio';
            default:
                return 'ayuda';
        }
    }

    public mostrar_saldo(): boolean {
        if (Number(this.codigo_forma_pago) === 0 && Number(this.saldo_vencido) > 0) {
            return true;
        }
        return false;
    }

    public get_descripcion_multiproducto(): string {
        return this.descripcion_multiproducto;
    }

    public get_descripcion_forma_pago_tarjeta_circulacion(): string {
        let descripcionPago = '';

        switch (this.get_codigo_forma_pago()) {
            case 3:
                descripcionPago = 'El importe será debitado de ' + this.get_descripcion_forma_pago();
                break;
            case 4:
                descripcionPago = 'El importe será debitado de CBU';
                break;
            case 8:
                descripcionPago = 'Adherido a débito automático';
                break;
        }
        return descripcionPago;
    }

    public getMoneda(): string {
        return this.moneda;
    }

    public toJSON(): any {
        return {
            asegurado: this.asegurado,
            descripcion_rama: this.descripcion_rama,
            poliza: this.poliza,
            fin_vigencia: this.fin_vigencia,
            inicio_vigencia: this.inicio_vigencia,
            emision: this.emision,
            descripcion_riesgo: this.descripcion_riesgo,
            tomador: this.tomador ? this.tomador.toJSON() : null,
            saldo_vencido: this.saldo_vencido,
            riesgos_auto: this.riesgos_auto.map(riesgoAuto => riesgoAuto.toJSON()),
            riesgos_vida: this.riesgos_vida.map(riesgoVida => riesgoVida.toJSON()),
            riesgos_varios: this.riesgos_varios.map(riesgoVario => riesgoVario.toJSON()),
            nro_riesgo: this.nro_riesgo,
            rama: this.rama,
            suplementos: this.suplementos.map(suplemento => suplemento.toJSON()),
            productor: this.productor,
            codigo_forma_pago: this.codigo_forma_pago,
            descripcion_forma_pago: this.descripcion_forma_pago,
            nro_combo: this.nro_combo,
            poliza_base: this.poliza_base,
            rama_base: this.rama_base,
            combo_secuencia: this.combo_secuencia,
            cantidad_cuotas: this.cantidad_cuotas,
            descripcion_multiproducto: this.descripcion_multiproducto,
            moneda: this.moneda
        };
    }

}

