import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './services/storage.service';

@Injectable({
    providedIn: 'root'
})

export class VigiaGuard  {
    constructor(
        private storage: StorageService,
        private router: Router,
    ) { }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const tokenOk = !this.storage.tokenVerify();
        if ((this.storage.get_token() === null) || (tokenOk)) {
            this.router.navigate(['/login']);
        }

        return true;
    }
}
