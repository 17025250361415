/* eslint-disable @typescript-eslint/naming-convention */

export class Tomador {

    private codigo_tomador: number = null;
    private nombre_tomador: string = null;
    private domicilio: string = null;
    private localidad: string = null;
    private codigo_postal: string = null;

    public constructor(codigo_tomador: number,
        nombre_tomador: string,
        domicilio: string,
        localidad: string,
        codigo_postal: string) {

        this.codigo_tomador = codigo_tomador;
        this.nombre_tomador = nombre_tomador;
        this.domicilio = domicilio;
        this.localidad = localidad;
        this.codigo_postal = codigo_postal;
    }

    public get_codigo_tomador(): number {
        return this.codigo_tomador;
    }

    public get_nombre_tomador(): string {
        return this.nombre_tomador;
    }

    public get_domicilio(): string {
        return this.domicilio;
    }

    public get_localidad(): string {
        return this.localidad;
    }

    public get_codigo_postal(): string {
        return this.codigo_postal;
    }

    public toJSON(): any {
        return {
            codigo_tomador: this.codigo_tomador,
            nombre_tomador: this.nombre_tomador,
            domicilio: this.domicilio,
            localidad: this.localidad,
            codigo_postal: this.codigo_postal,
        };
    }

    public tomadorFromJSON(data: any): any {
        const tomador = new Tomador(data.codigo_tomador, data.nombre_tomador, data.domicilio, data.localidad, data.codigo_postal);
    }
}
