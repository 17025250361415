<ion-app>
	<ion-router-outlet id="main-content"></ion-router-outlet>
	<ion-menu *ngIf="!verGesto()" contentId="main-content" menuId="menu-lateral" [swipeGesture]="!verGesto()">
		<ion-header class="menu-lateral-header">
			<ion-toolbar>
				<ion-title>Menú asegurado</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content class="menu-lateral-content">
			<!-- Logo -->
			<ion-item>
				<div class="logo-header" style="background-image: url(assets/imgs/logo.png);"></div>
			</ion-item>
			<!-- Listado de items -->
			<ion-list>
				<ion-menu-toggle>
					<ion-item routerLink="/index">
						<img class='icono' src="assets/icon/poliza.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Mis pólizas
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/asegurados">
						<img class='icono' src="assets/icon/asegurados.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Asegurados
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/notificaciones">
						<img class='icono' src="assets/icon/bell-solid.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Notificaciones
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/promociones">
						<img class='icono' src="assets/icon/gift-solid.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Beneficios
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/contact">
						<img class='icono' src="assets/icon/contacto.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Contacto
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/frequent-questions">
						<img class='icono' src="assets/icon/ayuda.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Preguntas frecuentes
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/ajustes">
						<img class='icono' src="assets/icon/gear-solid.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Ajustes
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/index-siniestro">
						<img class='icono' src="assets/icon/car-burst-solid.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Siniestros
					</ion-item>
				</ion-menu-toggle>
				<ion-menu-toggle>
					<ion-item routerLink="/login" (click)="close_session()">
						<img class='icono' src="assets/icon/cerrar.svg"
							style="width:20px!important;height:20px!important;margin-right:10px;margin-left:10px;" />
						Cerrar sesión
					</ion-item>
				</ion-menu-toggle>
			</ion-list>
			<!-- Información -->
			<ion-item>
				<ion-label class="ion-text-wrap">
					La Perseverancia Seguros S.A. Nº de inscripción en SSN
					0157 | Atención al asegurado: 0800-6668400
				</ion-label>
			</ion-item>
		</ion-content>
	</ion-menu>
</ion-app>