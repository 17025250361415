import { environment } from './../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Network } from '@capacitor/network';
import { Notificacion } from './../model/Notificacion';

@Injectable({
    providedIn: 'root',
})
export class RestService {
    private api = environment.baseUrl;
    private connected: boolean;
    private dataSource = new BehaviorSubject(true);
    private cant_horas_para_recarga = 1;
    private cant_horas_para_recarga_notificaciones_push = 24;

    currentData = this.dataSource.asObservable();

    constructor(private http: HttpClient, private storage: StorageService) {
        /* Listener  de conexion a internet */
        Network.addListener('networkStatusChange', (status_in) => {
            this.connected = status_in.connected;
            this.get_estado_red(this.connected);
        });
        /* Fin listener conexion a internet */
        Network.getStatus().then((e) => {
            this.connected = e.connected;
            this.get_estado_red(this.connected);
        });
    }

    public login(payload: any): Observable<object> {
        const path = `${this.api}/usuario/login`;
        const data = this.http.post(path, payload);
        return data;
    }

    public sendSolicitudAlta(payload: any): Observable<object> {
        const path = `${this.api}/usuario/registrar_email`;
        const createUserMail = this.http.post(path, payload);
        return createUserMail;
    }

    public sendCode(payload: any): Observable<object> {
        const path = `${this.api}/usuario/validar_email`;
        const codeVerify = this.http.post(path, payload);
        return codeVerify;
    }

    public getQuestion(payload: any): Observable<object> {
        const path = `${this.api}/asegurado/pregunta_verificacion`;
        const data = this.http.post(path, payload);
        return data;
    }

    public validateQuestion(payload: any): Observable<object> {
        const path = `${this.api}/asegurado/agregar_asegurado`;
        const data = this.http.post(path, payload);

        return data;
    }

    public save_password(payload: any): Observable<object> {
        const path = `${this.api}/usuario/grabar_password`;
        const data = this.http.post(path, payload);
        return data;
    }

    public sendContactForm(payload: any): Observable<object> {
        const path = `${this.api}/auxiliar/email_soporte`;
        const contactForm = this.http.post(path, payload);
        return contactForm;
    }

    public sendCommentForm(payload: any): Observable<object> {
        const path = `${this.api}/auxiliar/email_comentario`;
        const commentForm = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`
            }
        });
        return commentForm;
    }

    public sendSolicitudRecoverPassword(payload: any): Observable<object> {
        const path = `${this.api}/usuario/recuperar_password`;
        const createUserMail = this.http.post(path, payload);
        return createUserMail;
    }

    public deleteAsegurado(payload: any): Observable<object> {
        const path = `${this.api}/asegurado/eliminar_asegurado`;
        const deleteUser = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        this.storage.set_agrega_elimina_asegurado('true');

        return deleteUser;
    }

    public confirmNewPassword(payload: any): Observable<object> {
        const path = `${this.api}/usuario/grabar_password`;
        const newPassword = this.http.post(path, payload);
        return newPassword;
    }

    public obtenerPoliza(payload: any): string {
        const path =
            'https://com-prod.lps.com.ar/LPS_COM_PROD/servlet/ar.com.glmsa.seguros.comercial.glmmobile.aimprimirpoliza?' +
            'GLM,' +
            '1,' +
            payload.rama +
            ',' +
            payload.poliza +
            ',' +
            payload.suplemento;
        return path;
    }

    public obtener_polizas_asegurado(payload: any): Observable<object> {
        const hayConexion = this.connected;

        if (hayConexion) {
            const path = `${this.api}/poliza/obtener_polizas_resumen`;
            const polizas_asegurado = this.http.post(path, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.storage.get_token()}`,
                },
            });
            localStorage.removeItem('polizas');
            return polizas_asegurado;
        }

        return this.get_info_with_localStorage('polizas');
    }

    public obtener_asegurados_email(payload: any): Observable<any> {
        const hayConexion = this.connected;

        // Busca los asegurados si se excedió de las horas necesarias para la recarga o si se agrego o elimino un asegurado
        if (
            this.diferencia_horaria(
                this.cant_horas_para_recarga,
                this.get_updated()
            ) ||
            this.storage.get_agrega_elimina_asegurado() !== null
        ) {
            if (hayConexion) {
                localStorage.removeItem('asegurados');
                const path = `${this.api}/asegurado/obtener_asegurados`;
                const asegurados = this.http.post(path, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.storage.get_token()}`,
                    },
                });
                this.set_updated();
                //this.storage.set_asegurados(JSON.stringify(asegurados));
                return asegurados;
            }
        }
        return this.get_info_with_localStorage('asegurados');
    }

    public get_pay_link(payload: any): Observable<object> {
        const path = `${this.api}/mp/obtener_cupon_de_pago`;
        const data = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return data;
    }

    public get_cupon_rul(payload: any): Observable<object> {
        const path = `${this.api}/poliza/comprobante_cuota`;
        const data = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return data;
    }

    public get_updated(): Date {
        if (localStorage.getItem('updated') === null) {
            return new Date(-24 * 3600 * 1000);
        }
        return new Date(localStorage.getItem('updated').toString());
    }

    public remove_updated(): void {
        localStorage.removeItem('updated');
    }

    public get_estado_red(estado: boolean): any {
        return this.dataSource.next(estado);
    }

    public change_password(payload: any) {
        const path = `${this.api}/usuario/cambiar_password`;
        const cambiar_pass = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return cambiar_pass;
    }

    public diferencia_horaria(
        cantidad_horas_para_recarga: number,
        function_date: any
    ): boolean {
        //const diferencia_en_milisegundos = new Date().getTime() - new Date(this.get_updated()).getTime();
        const diferencia_en_milisegundos =
            new Date().getTime() - new Date(function_date).getTime();
        const horas = diferencia_en_milisegundos / (1000 * 60 * 60);
        return horas > cantidad_horas_para_recarga;
    }

    private get_info_with_localStorage(name: string) {
        const test = JSON.parse(localStorage.getItem(name));
        return of(test);
    }

    private set_updated() {
        localStorage.setItem('updated', new Date().toString());
    }

    public actualizar_token_push(): boolean {
        const date_token = this.storage.get_token_push();
        if (date_token === null) {
            this.storage.save_token_push_date();
            return true;
        }

        return !this.diferencia_horaria(
            this.cant_horas_para_recarga_notificaciones_push,
            this.storage.get_token_push()
        );
    }

    public save_token_push(token: string): boolean {
        const path = `${this.api}/usuario/guardar_token_notificaciones_push`;
        const payload = {
            email: this.storage.get_mail(),
            token: token,
        };
        const save_token = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        save_token.subscribe(
            (response) => {
                return response;
            },
            (error) => {
                return false;
            }
        );

        return false;
    }

    public async hay_conexion(): Promise<any> {
        let connection_status;
        await Network.getStatus().then((e) => {
            connection_status = e.connected;
        });

        return connection_status;
    }

    public activar_cargando(): boolean {
        return this.diferencia_horaria(
            this.cant_horas_para_recarga,
            this.get_updated()
        );
    }

    public control_version(version: string, android: boolean, username: string) {
        const path = `${this.api}/auxiliar/control_version`;

        const payload = {
            version: version,
            android: android,
            username: username,
        };

        const control_version = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return control_version;
    }

    public get_notificaciones(): Observable<any> {
        const path = `${this.api}/notificaciones/obtener_notificaciones`;
        const payload = {
            id_usuario: 1,
            email_usuario: this.storage.get_mail(),
        };
        const listado = this.http.post<Notificacion>(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return listado;
    }

    public set_notificacion_leida(id: number) {
        const path = `${this.api}/notificaciones/marcar_notificacion_leida`;
        const payload = {
            email: this.storage.get_mail(),
            id_notificacion: id,
        };
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return respuesta.subscribe((response) => {
            response['datos'];
        });
    }

    public set_notificacion_eliminada(listado_de_id: Array<number>) {
        const path = `${this.api}/notificaciones/eliminar_notificaciones`;
        const payload = {
            email: this.storage.get_mail(),
            notificaciones: listado_de_id,
        };
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return respuesta.subscribe((response) => {
            response['datos'];
        });
    }

    public save_geolocalizacion(
        latitud: number,
        longitud: number,
        ubicacion: string
    ): any {
        //if (!this.coordenadas_guardadas_iguales(latitud, longitud)) {
        const path = `${this.api}/usuario/guardar_geolocalizacion`;
        const payload = {
            latitud: latitud,
            longitud: longitud,
            email: this.storage.get_mail(),
        };
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe((response) => {
            ubicacion =
                response['datos']['geolocalizacion'].provincia +
                ', ' +
                response['datos']['geolocalizacion'].ciudad;
            this.storage.set_ubicacion(ubicacion);
        });
        return respuesta;
    }

    private coordenadas_guardadas_iguales(
        latitud: number,
        longitud: number,
        ubicacion: string
    ): boolean {
        if (
            latitud === this.storage.get_latitud() &&
            longitud === this.storage.get_longitud()
        ) {
            return true;
        }
        this.storage.set_latitud(latitud);
        this.storage.set_longitud(longitud);
        this.storage.set_ubicacion(ubicacion);
        return false;
    }

    public guardar_ultimo_ingreso(): Observable<any> {
        const path = `${this.api}/usuario/guardar_fecha_ultimo_ingreso`;
        const payload = {
            email: this.storage.get_mail(),
        };
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe((response) => {});

        return respuesta;
    }

    public obtener_ubicacion(): void {
        const respuesta = this.http.get('https://ipapi.co/json/');
        respuesta.subscribe((response) => {
            this.save_geolocalizacion(
                parseFloat(response['latitude']),
                parseFloat(response['longitude']),
                ''
            );
        });
    }

    public controlar_cuotas_pagas_mercado_pago(cuota_string: Array<string>) {
        //{{app_asegurados_backend}}/api/mp/obtener_estado_pago_mp
        const path = `${this.api}/mp/obtener_estado_pago_mp`;
        const payload = {
            email: this.storage.get_mail(),
            cuota_string: cuota_string,
        };
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe((response) => {});
        return respuesta;
    }

    public cambiar_email(payload: any) {
        const path = `${this.api}/usuario/cambiar_email`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe((response) => {});

        return respuesta;
    }

    public solicitar_cambio_email(payload: any) {
        const path = `${this.api}/usuario/solicitar_cambio_email`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return respuesta;
    }

    public obtener_beneficios() {
        const path = `${this.api}/cupones_beneficios/banners_principales`;
        const respuesta = this.http.get(path, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return respuesta;
    }

    public obtener_categorias_y_localidades(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_filtros_cuponstar`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        return respuesta;
    }

    public buscar_cupones_por_localidad(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_cupones_por_localizacion`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return respuesta;
    }
    public buscar_por_query(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_cupones_por_query`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return respuesta;
    }

    public buscar_cupones_por_categoria(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_cupones_por_categoria`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return respuesta;
    }

    public obtener_cupon(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_codigo_cupon`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe();
        return respuesta;
    }

    public get_codigo_cupon(payload: any) {
        const path = `${this.api}/cupones_beneficios/obtener_codigo_cupon`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return respuesta;
    }

    public obtener_cupones_por_usuario() {
        const payload = {
            //"email": 'garridonelson@lps.com.ar'
            email: this.storage.get_mail(),
        };
        const path = `${this.api}/cupones_beneficios/obtener_cupones_por_usuario`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe();
        return respuesta;
    }

    public obtener_cupones_principales() {
        const payload = {
            //"email": 'garridonelson@lps.com.ar'
            email: this.storage.get_mail(),
        };
        const path = `${this.api}/cupones_beneficios/obtener_cupones_pagina_principal`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });
        respuesta.subscribe();
        return respuesta;
    }

    public obtener_certificado_cobertura(
        rama: number,
        poliza: number,
        suplemento: number
    ) {
        const path =
            environment.baseSOAP +
            `aimprimircertifcobertura?GLM,1,${rama},${poliza},${suplemento}`;

        return path;
    }

    public obtenerSiniestros(payload: any) {
        const path = `${this.api}/siniestro/obtener_siniestros`;
        const respuesta = this.http.post(path, payload, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${this.storage.get_token()}`,
            },
        });

        return respuesta;
    }
}
