/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Cobertura } from './Cobertura';

export class RiesgoVario {

    private descripcion_riesgo: string = null;
    private coberturas: Array<Cobertura> = [];

    public constructor(
        descripcion_riesgo: string,
    ) {
        this.descripcion_riesgo = descripcion_riesgo;
    }

    public agregar_coberturas(coberturas: any): void {
        if (Array.isArray(coberturas)) {
            coberturas.forEach(item => {
                const cobertura = new Cobertura(
                    item['Codigo'] || item['codigo'],
                    item['Nombre'] || item['nombre'],
                    item['Suma'] || item['suma'],
                );
                this.coberturas.push(cobertura);
            });
            return;
        }
    }

    public get_descripcion_riesgo(): string {
        return this.descripcion_riesgo;
    }

    public get_coberturas(): Array<Cobertura> {
        return this.coberturas;
    }

    public toJSON(): any {
        return {
            descripcion_riesgo: this.descripcion_riesgo,
            coberturas: this.coberturas.map(cobertura => cobertura.toJSON())
        };
    }

    public riesgoVarioFromJSON(data: any): any {
        const riesgoVario = new RiesgoVario(data.descripcion_riesgo);
    }

}
