/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable-next-line @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { Cobertura } from './Cobertura';

export class RiesgoAuto {
    private anio: string = null;
    private chasis: string = null;
    private cobertura_sigla: string = null;
    private cobertura_descripcion: string = null;
    private coberturas: Array<Cobertura> = [];
    private descripcion_riesgo: string = null;
    private gnc: string = null;
    private marca: string = null;
    private modelo: string = null;
    private motor: string = null;
    private nro_riesgo: string = null;
    private patente: string = null;
    private uso_vehiculo: string = null;
    private valor_asegurado: number = null;
    private adicional: string = null;

    public constructor(
        anio: string,
        chasis: string,
        cobertura_sigla: string,
        cobertura_descripcion: string,
        descripcion_riesgo: string,
        gnc: string,
        marca: string,
        modelo: string,
        motor: string,
        nro_riesgo: string,
        patente: string,
        uso_vehiculo: string,
        valor_asegurado: number
    ) {
        this.anio = anio;
        this.chasis = chasis;
        this.cobertura_sigla = cobertura_sigla;
        this.cobertura_descripcion = cobertura_descripcion;
        this.descripcion_riesgo = descripcion_riesgo;
        this.gnc = gnc;
        this.marca = marca;
        this.modelo = modelo;
        this.motor = motor;
        this.nro_riesgo = nro_riesgo;
        this.patente = patente;
        this.uso_vehiculo = uso_vehiculo;
        this.valor_asegurado = valor_asegurado;
    }

    public agregar_coberturas(coberturas: any): void {
        if (Array.isArray(coberturas)) {
            coberturas.forEach(item => {
                const cobertura_loop = new Cobertura(
                    item['codigo'],
                    item['nombre'],
                    item['suma']
                );
                this.coberturas.push(cobertura_loop);
            });
            return;
        }
        const cob = new Cobertura(
            coberturas['codigo'],
            coberturas['nombre'],
            coberturas['suma']
        );
        this.coberturas.push(cob);
        return;
    }

    public agregar_adicional(adicionales: any): void {
        if (typeof adicionales === 'string') {
            this.adicional = adicionales;
        }
    }

    public get_anio(): string {
        return this.anio;
    }

    public get_chasis(): string {
        return this.chasis;
    }

    public get_cobertura_sigla(): string {
        return this.cobertura_sigla;
    }

    public get_cobertura_descripcion(): string {
        return this.cobertura_descripcion;
    }

    public get_coberturas(): Array<Cobertura> {
        return this.coberturas;
    }

    public get_descripcion_riesgo(): string {
        return this.descripcion_riesgo;
    }

    public get_gnc(): string {
        return this.gnc.toUpperCase();
    }

    public get_marca(): string {
        return this.marca;
    }

    public get_modelo(): string {
        return this.modelo;
    }

    public get_motor(): string {
        return this.motor;
    }

    public get_nro_riesgo(): string {
        return this.nro_riesgo;
    }

    public get_patente(): string {
        return this.patente;
    }

    public get_uso_vehiculo(): string {
        return this.uso_vehiculo.toUpperCase();
    }

    public get_valor_asegurado(): number {
        return this.valor_asegurado;
    }

    public get_adicional(): string {
        return this.adicional;
    }

    public toJSON(): any {
        return {
            anio: this.anio,
            chasis: this.chasis,
            cobertura_sigla: this.cobertura_sigla,
            cobertura_descripcion: this.cobertura_descripcion,
            coberturas: this.coberturas.map(cobertura => cobertura.toJSON()),
            descripcion_riesgo: this.descripcion_riesgo,
            gnc: this.gnc,
            marca: this.marca,
            modelo: this.modelo,
            motor: this.motor,
            nro_riesgo: this.nro_riesgo,
            patente: this.patente,
            uso_vehiculo: this.uso_vehiculo,
            valor_asegurado: this.valor_asegurado,
            adicional: this.adicional
        };
    }

    public riesgoAutoFromJSON(data: any): any {
        const riesgoAuto = new RiesgoAuto(data.anio, data.chasis, data.cobertura_sigla, data.cobertura_descripcion, data.descripcion_riesgo, data.gnc, data.marca, data.modelo, data.motor, data.nro_riesgo, data.patente, data.uso_vehiculo, data.valor_asegurado);
    }
}
